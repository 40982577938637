import React from "react";
import shape01 from "../../assets/img/images/faq_shape01.png";
import shape02 from "../../assets/img/images/faq_shape02.png";
import shape03 from "../../assets/img/images/faq_shape03.png";
import FaqItem from "./FaqItem";

const Faq = () => {
  const faq_items = [
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Who are you?",
      details: `The Coin7 team consists of experts in software development, marketing, and business management, as well as five external blockchain programmers, all actively involved in the development and implementation of our project.

      We are a Liechtenstein-based company and highly value transparency and communication. We believe that our success is closely tied to the trust our investors and users have in us. Therefore, we regularly update on our project progress and communicate all important and significant steps, successes, and challenges openly on our website and through our social media channels.
      
      Our team works hard to make the most of Coin7 and create a reliable, innovative, and user-friendly network that provides real value to our users. The opinions and suggestions of our investors and users are greatly appreciated, and we take them into consideration in all of our decisions.
      
      We are immensely proud of the accomplishments we have achieved together, and we look forward to taking the platform further with confidence that we will achieve outstanding success.`,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "What is the goal?",
      details: `Our project’s aim is to expand the usage of cryptocurrencies by encouraging more people to be involved. With our app’s mining function, we are providing our users with a risk-free way to participate in the crypto world without making any financial investment. Our app is designed to start mining with just one click, and our MLM function enables even inexperienced users to recruit their family and friends to earn additional income and generate passive earnings. With this approach, we want to create a stronger connection between us and the Coin7 community, which helps to strengthen their trust.`,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "How will you invest the presale earnings?",
      details: `Our plan is to allocate a significant portion of the presale earnings towards funding the platform's growth, while also utilizing a portion of the capital generated to further enhance the project's infrastructure and advance its development.
      Our marketing expenses:
      Social Media Marketing: Our company will establish a strong presence on various social media channels, including Facebook, Twitter, Instagram, and LinkedIn. We will regularly post updates and engage with our target audience to create brand awareness and attract new users. 
      Influencer Marketing: We will collaborate with influencers who have large and engaged followings and leverage their communities to promote our app. 
      App Store Optimization: It is the company’s objective to optimize the app for relevant keywords in order to increase its visibility and ranking within the app store. 
      Paid Advertising: Paid advertising on different platforms, including Google Ads, is one of the planned strategies to enhance user acquisition and boost traffic towards our app.`,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Why does Coin7 have two different types of coins?",
      details: `Coin7 utilizes two distinct types of coins in order to better facilitate our unique ecosystem and to separate the functionalities and utilities within our platform.
      The first type is the Coin7 Business Coin. This coin represents the value of our company. As the business expands and becomes more successful, the value of the Business Coin is expected to increase, reflecting the growing value of Coin7 as a business entity. It provides an avenue for investors to share in the growth and success of our platform.
      The second type is the Coin7 Mining Coin. This coin can be mined by users through our application. The purpose of this coin is to facilitate transactions within the Coin7 platform and to reward users for their activity and engagement. Furthermore, we have exciting plans for the Mining Coin. We anticipate that it will be listed on major exchanges by mid-2024, expanding its reach and utility even further.
      By mining and using these coins, users become active participants in the Coin7 ecosystem, enabling them to benefit directly from their online interactions and activities.
      In essence, these two types of coins allow us to align the interests of our investors and users, while maintaining a clear distinction between the investment and transactional aspects of our platform. This dual-coin system is integral to our vision of democratizing the digital earning landscape and making the benefits of decentralized finance accessible to all.`,
    },
  ];

  return (
    <section id="faq" className="faq-area">
      <div className="container custom-container-four">
        <div className="faq-shape-wrap">
          <img src={shape01} alt="" className="img-one" />
          <img src={shape02} alt="" className="img-two rotateme" />
          <img src={shape03} alt="" className="img-three" />
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="section-title section-title-two text-center mb-60">
              <h2 className="title">Frequently asked questions</h2>
            </div>

            <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion" id="accordionExample">
                {faq_items.map((x, index) => (
                  <FaqItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
