import React, { useContext, useEffect, useState } from "react";
import About from "../components/About/About";
import BannerTwo from "../components/Banner/BannerTwo";
import ContactTwo from "../components/Contact/ContactTwo";
import CountDownTwo from "../components/CountDown/CountDownTwo";
// import CounterArea from "../components/CounterArea/CounterArea";
import DownloadArea from "../components/DownloadArea/DownloadArea";
import Faq from "../components/Faq/Faq";
// import Newsletter from "../components/Newsletter/Newsletter";
import RoadmapTwo from "../components/Roadmap/RoadmapTwo";
// import TeamTwo from "../components/Team/TeamTwo";
import WhyChooseUsTwo from "../components/WhyChooseUs/WhyChooseUsTwo";
import { AppContext } from "../context/AppContext";
import LayoutTwo from "../layouts/LayoutTwo";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import siteConfig from "../configs/site.config";
import CheckNetwork from "../components/CountDown/CheckNetwork";
import WhyChooseUs from "../components/WhyChooseUs/WhyChooseUs";
import SaleStratege from "../components/WhyChooseUs/SaleStratege";


const Home = () => {
  const {
    networkId,
    account,
    connectWallet,
    disconnectWallet,
    setReferrer,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false)
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      localStorage.setItem("referral", id);
      setReferrer(id);
    }

    // eslint-disable-next-line 
  }, [id])

  const handleConnect = () => {
    setLoading(true);
    connectWallet()
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.warning('Warning', error.message, 3000);
        setLoading(false);
      })
  }

  return (
    <LayoutTwo address={account} connect={handleConnect} disconnectWallet={disconnectWallet} >
      <main>
        <BannerTwo />
        {
          account && networkId !== siteConfig.NETWORK.ID ?
            <CheckNetwork />
            :
            <CountDownTwo
              address={account} connect={handleConnect} loading={loading}
            />
        }
        <About />
        <WhyChooseUsTwo />
        {/* <CounterArea /> */}
        <RoadmapTwo />
        <SaleStratege />
        <WhyChooseUs />
        {/* <TeamTwo /> */}
        <DownloadArea />
        <Faq />
        <ContactTwo />
        {/* <Newsletter /> */}
      </main>
    </LayoutTwo>
  );
};

export default Home;
