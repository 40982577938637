/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import icon from "../../assets/img/icon/scroll_icon.svg";
import bannerImg from "../../assets/img/banner/banner_img.png";
import { Link } from "react-router-dom";
import { handleClickScroll } from "../../lib/helpers";

const BannerTwo = () => {
  const handleSocialLink = (name) => {
    switch (name) {
      case "facebook":
        window.open("https://www.facebook.com/coin7.org", "_blank")
        break;
      case "youtube":
        window.open("https://www.youtube.com/@coin7-official", "_blank")
        break;
      case "twitter":
        window.open("https://twitter.com/coin7official", "_blank")
        break;
      case "telegram":
        window.open("https://t.me/coin7official", "_blank")
        break;
      case "instagram":
        window.open("https://www.instagram.com/coin7official/", "_blank")
        break;

      default:
        break;
    }
    
  }
  return (
    <section className="banner-area-two">
      <div className="banner-bg-two"></div>
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="banner-content text-center" style={{ marginBottom: '180px' }}>
              {/* <h2 className="title wow fadeInDown" data-wow-delay=".2s">
                World’s First Marketplace Based On CryptoCurrencies
              </h2> */}
              <img src={bannerImg} alt="img" style={{ width: '1000px', marginBottom: '50px' }} />
            </div>
          </div>
        </div>
      </div>
      <div className="banner-social-wrap">
        <ul>
          <li>
            <a onClick={() => handleSocialLink("facebook")} className="banner-social-link" style={{cursor: 'pointer'}}>
              <i className="fab fa-facebook-square"></i>
            </a>
            <span>Facebook</span>
          </li>
          <li>
            <a onClick={() => handleSocialLink("twitter")} className="banner-social-link" style={{cursor: 'pointer'}}>
              <i className="fab fa-twitter"></i>
            </a>
            <span>Twitter</span>
          </li>
          <li>
            <a onClick={() => handleSocialLink("telegram")} className="banner-social-link" style={{cursor: 'pointer'}}>
              <i className="fab fa-telegram"></i>
            </a>
            <span>Telegram</span>
          </li>
          <li>
            <a onClick={() => handleSocialLink("instagram")} className="banner-social-link" style={{cursor: 'pointer'}}>
              <i className="fab fa-instagram"></i>
            </a>
            <span>Instagram</span>
          </li>
          <li>
            <a onClick={() => handleSocialLink("youtube")} className="banner-social-link" style={{cursor: 'pointer'}}>
              <i className="fab fa-youtube"></i>
            </a>
            <span>Youtube</span>
          </li>
        </ul>
      </div>
      <div className="banner-scroll">
        <span>Scroll down</span>
        <Link
          to="#about"
          data-target="#about"
          onClick={() => handleClickScroll("about")}
        >
          <img src={icon} alt="" />
        </Link>
      </div>
    </section>
  );
};

export default BannerTwo;
