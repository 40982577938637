/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";
import React from "react";
import { scrollToTop } from "../../lib/helpers";

const FooterTwo = () => {
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSocialLink = (name) => {
    switch (name) {
      case "facebook":
        window.open("https://www.facebook.com/coin7.org", "_blank")
        break;
      case "youtube":
        window.open("https://www.youtube.com/@coin7-official", "_blank")
        break;
      case "twitter":
        window.open("https://twitter.com/coin7official", "_blank")
        break;
      case "telegram":
        window.open("https://t.me/coin7official", "_blank")
        break;
      case "instagram":
        window.open("https://www.instagram.com/coin7official/", "_blank")
        break;

      default:
        break;
    }
    
  }

  return (
    <footer>
      <div className="footer-area-two">
        <div className="container custom-container-four">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-8">
                <div className="footer-menu-two">
                  <ul className="navigation">
                    <li>
                      <Link to="#about" onClick={() => handleClickScroll("about")}>About us</Link>
                    </li>
                    <li>
                      <Link to="#roadmap" onClick={() => handleClickScroll("roadmap")}>Roadmap</Link>
                    </li>
                    <li>
                      <Link to="#faq" onClick={() => handleClickScroll("faq")}>Faq</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-social">
                  <ul>
                    <li>
                      <a onClick={() => handleSocialLink("facebook")} style={{ cursor: 'pointer' }}>
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => handleSocialLink("twitter")} style={{ cursor: 'pointer' }}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => handleSocialLink("telegram")} style={{ cursor: 'pointer' }}>
                        <i className="fab fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => handleSocialLink("instagram")} style={{ cursor: 'pointer' }}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => handleSocialLink("youtube")} style={{ cursor: 'pointer' }}>
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom mt-5">
            <div className="row">
              <div className="col-lg-5">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023. All Rights Reserved Coin7</p>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="scroll-up text-center">
                  <button
                    className="scroll-to-target"
                    data-target="html"
                    onClick={scrollToTop}
                  >
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <span>scroll Top</span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="footer-bottom-menu">
                  <ul>
                    <li>
                      <Link to="#">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
