/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

const configs =  {
  // eslint-disable-next-line
  NETWORK: {
    NAME: "Ethereum",
    SYMBOL: "ETH",
    ID: 1,
    RPC_URI: "https://mainnet.infura.io/v3/",
    BLOCK_EXPLORE_URL: "https://etherscan.io",
  },
  SITE_URL: "https://presale.teleint.com/",
  COIN7_CONTRACT_ADDRESS: "0x5516a6e0cC74077061c86B2AEe46DcbE96582333",
  CROWDSALE_CONTRACT_ADDRESS: "0x8bbCeEA6165B251506c97350e7B96272862D1a13",
  PRESALE_CONTRACT_ADDRESS: "0x9EE9d71B475b8b3900eA80e87cD25751C8599164",
  USDT_CONTRACT_ADDRESS: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  PRICE_ORACLE: "0x75AE3Cab7A66a4E416BE05666362aa6f2eD245D1",
  USDT_DECIMAL: 6
}

export default configs